<template>
   <div class="wrap">
       <div class="con_top"><h2><span>■</span> 전화문의 관리</h2></div>

       <div class="btn-group">
           <div class="right">
               <button type="button" class="down-btn" @click="excelDownload()">엑셀다운로드</button>
               <button type="button" class="register" @click="addRow()">등록</button>
           </div>
       </div>

       <div class="search-group">
           <div class="left">
               <select class="font-16 w-100px h-30px pl-10" v-model="search.receptGender">
                   <option value="">성별</option>
                   <option value="남자">남자</option>
                   <option value="여자">여자</option>
               </select>

               <select class="font-16 w-100px h-30px pl-10 ml-10" v-model="search.receptStatus">
                   <option value="">상태</option>
                   <option value="처리중">처리중</option>
                   <option value="처리완료">처리완료</option>
               </select>

               <input type="text" placeholder="신청자 / 연락처 / 이메일" class="w-200px h-30px ml-10" @keyup.enter="getCustomerService()"  v-model="search.searchText">
               <a class="btn_search ml-10 pointer"  @click="searchCustomerService()">Search<span></span></a>
           </div>
           <div class="right">
               작성일 검색
               <crm-datepicker v-model="search.receptStartDate"  class="ml-10" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
               <crm-datepicker v-model="search.receptEndDate"  :parentDate="endDate"  format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
               <a class="btn_search ml-10 pointer" @click="searchCustomerService()">Search<span></span></a>
           </div>
       </div>

       <div class="con_table mt-40">
           <table class="narrow_table">
               <tbody v-if="loading">
                   <tr>
                       <th>No</th>
                       <th>신청자</th>
                       <th>성별</th>
                       <th>연락처</th>
                       <th>이메일</th>
                       <th>현황</th>
                       <th>상태</th>
                       <th>최근 업데이트일</th>
                       <th>작성자</th>
                       <th>관리</th>
                    </tr>
                    <tr>
                        <td colspan="10">검색중입니다.</td>
                    </tr>
               </tbody>
               <tbody v-else>
                   <tr>
                       <th>No</th>
                       <th>신청자</th>
                       <th>성별</th>
                       <th>연락처</th>
                       <th>이메일</th>
                       <th>현황</th>
                       <th>상태</th>
                       <th>최근 업데이트일</th>
                       <th>작성자</th>
                       <th>관리</th>
                    </tr>
                    <tr v-for="(item,index) in customerServiceList" :key="index" :class="currentRow === index ? 'current-row' : ''">
                        <td>{{ item.rownum }}</td>
                        <td>
                            <validation-provider rules="required" ref="receptName" v-slot="{ errors }">
                                <input  v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" type="text"  :class="{error: errors[0]}" name="receptName" v-model="item.receptName" class="w-60px h-20px">
                                <span v-else>{{ item.receptName }}</span>
                            </validation-provider>
                            
                        </td>
                        <td>
                            <validation-provider rules="required" ref="receptGender" v-slot="{ errors }">
                                <select v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" class="w-70px h-30px" v-model="item.receptGender" :class="{error: errors[0]}">
                                    <option value="">성별</option>
                                    <option value="남자">남자</option>
                                    <option value="여자">여자</option>
                                </select>
                                <span v-else> {{ item.receptGender }} </span>
                            </validation-provider>
                        </td>
                        <td>
                            <validation-provider rules="required" ref="receptPhone" v-slot="{ errors }">
                                <input v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" type="text" v-model="item.receptPhone" class="w-100px h-20px" :class="{error: errors[0]}">
                                <span v-else>{{ item.receptPhone }}</span>
                            </validation-provider>
                        </td>
                        <td>
                            <validation-provider rules="required" ref="receptEmail" v-slot="{ errors }">
                                <input v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" type="text" v-model="item.receptEmail" class="w-150px h-20px" :class="{error: errors[0]}">
                                <span v-else>{{ item.receptEmail }}</span>
                            </validation-provider>
                        </td>
                        <td>
                            <validation-provider rules="required" ref="receptMemo" v-slot="{ errors }">
                                <input v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" type="text" v-model="item.receptMemo" class="w-150px h-20px" :class="{error: errors[0]}">
                                <span v-else>{{ item.receptMemo }}</span>
                            </validation-provider>
                        </td>
                        <td>
                            <validation-provider rules="required" ref="receptStatus" v-slot="{ errors }">
                                <select v-if="item.rowType === 'NEW' || item.rowType === 'EDIT'" v-model="item.receptStatus" class="w-90px h-30px" :class="{error: errors[0]}">
                                    <option value="">상태선택</option>
                                    <option value="처리중">처리중</option>
                                    <option value="처리완료">처리완료</option>
                                </select>
                                <span v-else> {{ item.receptStatus }} </span>
                            </validation-provider>
                        </td>
                        <td>
                            {{ item.receptUpdatedate }}
                        </td>
                        <td>
                            {{ item.updateStaff }}
                        </td>
                        <td>
                            <!-- NEW , NORMAL , EDIT -->
                            <div v-if="item.rowType === 'NEW'">
                                <button class="btn" @click="saveCustomerService(item, index)">저장</button> <button class="btn" @click="cancel(index)">취소</button>
                            </div>
                            <div v-else-if="item.rowType === 'EDIT'">
                                <button class="btn" @click="updateCustomerService(item)">저장</button> <button class="btn" @click="rollBack(index)">취소</button>
                            </div>
                            <div v-else>
                                <button class="btn" @click="changeRowType(index,'EDIT')">수정</button> <button class="btn" @click="rowConfirm(item.idx)">삭제</button>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="customerServiceList.length === 0">
                        <td colspan="10">검색 결과가 없습니다.</td>
                    </tr>
               </tbody>
           </table>
       </div>

        <div class="con_table">
            <div class="page">
                <ul>
                    <li>
                        <a class="pointer" > &lt; </a>
                    </li>
                    <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                        <span class="pointer" style="padding: 8px;" @click="paging(index)">
                            <a :class="{'bold': index === search.pageNum}">{{index}}</a>
                        </span>
                    </li>
                    <li>
                        <a class="pointer" > &gt; </a>
                    </li>
                </ul>
            </div>
        </div>
   </div>
</template>
<script>
import _ from "lodash";
import { FormatDate } from '@/utils/common.js';
import FileSaver from 'file-saver'
export default {
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        original:[],
        dummy:[],

        search:{
            pageNum: 1,
            pageSize: 10,
            receptStartDate: '',
            receptEndDate: '',
            receptGender: '',
            receptStatus: '',
            searchText: ''
        },
        customerServiceList:[],//전화문의 리스트

        loading: false,

        currentRow: -1,

        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
    }),

    created(){
        this.getCustomerService();
    },

    mounted(){

    },

    methods: {

        /**
         * @description 페이징 초기화
         */
        searchCustomerService(){
            this.search.pageNum = 1;
            this.getCustomerService();
        },

        /**
         * @description : setChangeDateType
         */
        setChangeDateType(){
            if(typeof this.search.receptStartDate === 'object'){
                this.search.receptStartDate = FormatDate(this.search.receptStartDate);
            }

            if(typeof this.search.receptEndDate === 'object'){
                this.search.receptEndDate = FormatDate(this.search.receptEndDate);
            }
        },
        /**
         * @description : 전화문의 관리 List
         */
        getCustomerService(){
            this.setChangeDateType();
            this.loading = true;
            this.axios.get(`/api/v1/recept/list`,{
                    params: this.search
                })
                .then((res) => {
                    if(res.data.err === 0){
                        this.original = this.setRowType(res.data.resList);
                        this.copy();

                        this.listLength = res.data.resListCount;

                        this.maxPagingCount = Math.ceil(this.listLength / this.search.pageSize);
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10);
                        this.pagePart = Math.ceil(this.search.pageNum / 10);
                    }
                    else{
                        if(res.data.err !== 105){
                            alert(res.data.result);
                        }
                        this.original = [];
                        this.customerServiceList = [];
                    }
                })
                .catch(err => {
                    console.error(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login');
                    } else {
                        alert('관리자에게 문의하세요');
                    }
                })
                .finally(()=>{
                    this.loading = false;
                });
        },

        /**
         * @description : original => customerServiceList
         */
        copy(){
            this.customerServiceList = _.cloneDeep(this.original);


            for(let i = 0 ; i < this.customerServiceList.length ; i++){
                const obj = Object.assign({},this.original[i]);
                this.customerServiceList[i].original = obj;
            }
        },

        /**
         * @description : 신규 추가
         */
        addRow(){
            const newRow = {
                receptName: '',
                receptGender:'',
                receptPhone: '',
                receptEmail: '',
                receptMemo:'',
                receptStatus:'',
                rowType:'NEW'
            }
            this.customerServiceList.unshift(newRow);
            this.currentRow = 0;
            this.resetValidate();
        },

        /**
         * @description : 확인 confirm
         */
        rowConfirm(idx){
            const find = this.customerServiceList.find(item => item.idx === idx);
            if(confirm(`${find.receptName} 삭제하시겠습니까?`)){
                this.deleteRecept(idx);
            }
        },

        /**
         * @description : 신규건 취소
         */
        cancel(index){
            this.currentRow = -1;
            this.customerServiceList.splice(index,1);
        },

        /**
         * @description : 상태값 변경
         * @param index 변경될 index
         * @param rowTypeNm 변경하고 싶은 rowTypeNm
         */
        changeRowType(index, rowTypeNm){
            this.currentRow = index;
            this.customerServiceList[index].rowType = rowTypeNm;
        },

        /**
         * @description : RollBack
         */
        rollBack(index){
            this.currentRow = -1;
            for(let key in this.customerServiceList[index]){
                if(key !== 'original'){
                    if(this.customerServiceList[index]['original']){
                        this.customerServiceList[index][key] = this.customerServiceList[index]['original'][key];
                    }
                }
            }
        },

        /**
         * @description : 전화문의 저장
         */
        async saveCustomerService(item,idx){
            let validCount = 0;
            this.currentRow = idx;
            for(let item in this.$refs){
              const {valid} =  await this.$refs[item][idx].validate();
              if(!valid){
                  validCount++;
              }
            }

            if(validCount > 0){
                alert('필수 값을 확인해주세요.')
                return;
            }

            this.axios.post('/api/v1/recept',item)
                .then(res =>{
                    if (res.data.err === 0) {
                        alert('저장되었습니다.');
                        this.getCustomerOne(res.data.idx_reception, item);//
                    }else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.error(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        /**
         * @description : 성공후 idx값
         */
        getCustomerOne(idx, item){
            this.axios.get(`/api/v1/recept/${idx}`)
                .then((res)=>{
                    if(res.data.err === 0){
                        item.receptUpdatedate = res.data.resVO.receptUpdatedate;
                        item.updateStaff = res.data.resVO.updateStaff;
                        item.idx = idx;
                        item.rownum = this.getMaxRowNum();
                        item.rowType = 'NORMAL';//NORMAL

                        const obj = Object.assign({},res.data.resVO);
                        obj.rownum = this.getMaxRowNum();
                        obj.rowType = 'NORMAL';
                        item.original = obj;
                    }else{
                        alert(res.data.result);
                    }
                })
                .catch(err => {
                    console.error('error : ', err);
                    alert('저장에 실패하였습니다.');
                })
        },

        /**
         * @description : 전화문의 수정
         */
        updateCustomerService(item){
            this.axios.put('/api/v1/recept', item)
                .then((res)=>{
                    if(res.data.err === 0){
                        alert('수정에 성공하였습니다.');
                        this.afterUpdate(item);
                    }
                    else{
                        alert(res.data.result);
                    }
                })
                .catch(err => {
                    console.error('error : ', err);
                    alert('수정에 실패하였습니다.');
                })
        },

        /**
         * @description : Update 후 getOne
         */
        afterUpdate(item){
            this.axios.get(`/api/v1/recept/${item.idx}`)
                .then((res)=>{
                    if(res.data.err === 0){
                        item.receptUpdatedate = res.data.resVO.receptUpdatedate;
                        item.updateStaff = res.data.resVO.updateStaff;
                        item.rowType = 'NORMAL';//NORMAL

                        const obj = Object.assign({},res.data.resVO);
                        obj.rowType = 'NORMAL';
                        item.original = obj;

                    }else{
                        alert(res.data.result);
                    }
                })
                .catch(err => {
                    console.error('error : ', err);
                })
        },

        /**
         * @description : resetValidate
         */
        resetValidate(){
            for(let index = 0 ; index < this.customerServiceList.length ; index++){
                for(let item in this.$refs){
                    if(this.$refs[item][index]){
                        this.$refs[item][index].reset();
                    }
                }
            }
        },

        /**
         * @description : setRowType
         */
        setRowType(array){
            for(let i = 0 ; i < array.length ; i++){
                array[i].rowType = 'NORMAL';
            }

            return array || [];
        },

        /**
         * @description : 로우 넘버 Max
         */
        getMaxRowNum(){
            if(this.customerServiceList.length > 1){
                return this.customerServiceList[1].rownum ? this.customerServiceList[1].rownum + 1: 1;
            }
            else{
                return 1;
            }
        },

        /**
         * @description : deleteRecept
         */
        deleteRecept(idx){
            this.axios.delete(`/api/v1/recept/${idx}`)
                .then((res)=>{
                    if(res.data.err === 0){
                        const findIndex = this.customerServiceList.findIndex(item => item.idx ===idx);
                        if(findIndex>-1){
                            this.customerServiceList.splice(findIndex,1);
                            alert(res.data.result);
                        }
                        else{
                            alert('존재하지 않는 행입니다.')
                        }
                    }
                    else{
                        alert(res.data.result);
                    }

                }).catch(err => {
                    console.error(`deleteRecept ${err}`);
                    alert(`삭제에 실패 하였습니다.`);
                })
        },

         /**
         * @description : 현재 페이지의 페이지 범위 계산
         */
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray;
        },

        /**
         * @description : 엑셀 다운로드
         */
        excelDownload(){
            this.setChangeDateType();

            const params ={
                receptGender: this.search.receptGender,
                receptStatus: this.search.receptStatus,
                searchText: this.search.searchText,
                receptStartDate: this.search.receptStartDate,
                receptEndDate: this.search.receptEndDate
            };

            const headers = {
                'Content-Type': 'ms-vnd/excel;charset=UTF-8'
            };

            this.axios.defaults.headers.post[headers];

            this.axios.get('/api/v1/excel/reception-list', {
                 params: params,
                 responseType: 'arraybuffer'
            }).then(res => {
                 FileSaver.saveAs(new Blob([res.data]), "customer_service_template.xlsx")
            }).catch(err => {
                console.error(err);
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        /**
         * @description : 현재 페이징
         */
        paging(index) {
            this.search.pageNum = index
            this.getCustomerService();
        },
    }
}
</script>

<style lang="scss">
    .register{
        background: #fdd000;
        padding: 3px 20px 6px 20px;
        border: 1px solid #fdd000;
        color: #000;
        margin-left: 10px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
    }

    .btn-group{
        overflow: hidden;
        .right{
            float: right;
        }
    }

    .search-group{
        margin-top: 10px;
        margin-bottom: 70px;

        .left{
            float: left;
        }

        .right{
            margin-top: 3px;
            float: right;
        }
    }

    .btn {
        background: #fff;
        padding: 3px 20px 6px 20px;
        border: 1px solid #dcdcdc;
        color: #000;
        border-radius: 4px;
        cursor: pointer;
    }

    .error{
        border-color: red !important;
    }

    .current-row{
        background-color: #e9e9e9;
    }

    .behind{
        z-index: -1;
    }
</style>